import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Icon, Input, Select } from 'semantic-ui-react';
import { buildClassNames, notEmptyString } from '../../modules/utils';

const typeMap = {
  web: 'Web',
  instagram: 'Instagram',
  facebook: 'Facebook',
  twitter: 'Twitter',
  pinterest: 'Pinterest'
}

const getUriTail = (uri = '', domain = '') => {
  if (typeof uri === 'string') {
    if (uri.includes('/') || uri.includes('@')) {
      const tail = uri.replace(/\/$/,'').split('/').pop().trim();
      return tail.includes(domain)? '/' : tail;
    } else {
      return uri;
    }
  } else {
    return '';
  }
}

class LinkItem extends React.Component {
  
  state = {
    itemUri: '',
    itemType: '',
  }

  componentDidMount() {
    const { uri, type } = this.props
    this.setState({
      itemUri: uri,
      itemType: type
    })
  }

  onChange = (e, element) => {
    const { value, name } = element;
    switch (name) {
      case 'type':
        this.setState({ itemType: value });
        break
      case 'uri':
        this.setState({ itemUri: value });
        break
    }
  }


  completeUri = () => {
    const { itemUri, itemType } = this.state
    const { index } = this.props
    let uri = itemUri;
    if (notEmptyString(uri) && notEmptyString(itemType)) {
      switch (itemType) {
        case 'facebook':
          if (!/^https?\/\/(www)?\.facebook\.com/.test(uri)) {
            uri = 'https://www.facebook.com/' + getUriTail(uri, 'facebook.com');
          }
          break
        case 'twitter':
          if (!/^https?\/\/(www)?\.twitter.com/.test(uri)) {
            uri = 'https://twitter.com/' + getUriTail(uri, 'twitter.com').replace(/^@/, '');
          }
          break
        case 'pinterest':
          if (!/https?\/\/(www)?\.pinterest.\w+/.test(uri)) {
            uri = 'https://www.pinterest.co.uk/' + getUriTail(uri, 'pinterest.').replace(/^@/, '');
          }
          break
        case 'instagram':
          if (!/^https?\/\/(www)?\.instagram.\w+/.test(uri)) {
            uri = 'https://www.instagram.com/' + getUriTail(uri, 'instagram.').replace(/^@/, '');
          }
          break
      }
      uri = uri.replace(/^(https?):\/\w+/, '$1://')
      if (!/^https?:\/\//.test(uri)) {
        uri = ['https', uri].join('://')
      }
      this.setState({itemUri: uri});
      const { update } = this.props;
      if (update instanceof Function) {
        update({uri, type: itemType, index })
      }
    }
  }

  remove = () => {
    const { update, index } = this.props;
    if (update instanceof Function) {
      update({ remove: index })
    }
  }

  render() {
    let { itemType, itemUri } = this.state
    const options = Object.entries(typeMap).map(([value, text]) => {
      return { value, text };
    });
    const wrapperClass = buildClassNames(["uri-selector", "row", itemType]);
    return (
      <div className={wrapperClass} onMouseLeave={this.completeUri}> 
        <Input name='uri' type='uri' defaultValue={itemUri} onChange={this.onChange} className="uri">
            <Select placeholder="Type" name='type' options={options} value={itemType} onChange={this.onChange} className="type" />
            <input />
            <Icon className="trash" onClick={this.remove} title="Delete link" />
        </Input>
      </div>
    )
  }
}

LinkItem.propTypes = {
  type: PropTypes.string,
  uri: PropTypes.string,
  update: PropTypes.func,
  index: PropTypes.number
};

LinkItem.defaultProps = {
  type: "",
  uri: "",
  index: 0,
};

export default LinkItem;
