import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import Picture from "./picture";
import ImageEdit from "../gallery/edit";
import { Rnd } from "react-rnd";
import renderHTML from 'react-render-html';
import { addRemoveImageToCuration, saveClaimApproved } from "../../modules/api";
import { addBodyClass, extractEmbedsFromUser, extractId, extractPlacenameAndGeo, notEmptyString } from "../../modules/utils";
import { validPlayerUrlType } from "../../media-validator";

class MainPhoto extends Component {
  state = {
    editMode: false,
    curations: [],
    showXL: false,
    position: { x: 0, y: 0 }
  };

  componentDidMount = () => {
    const { img, curations, mayAddToCurations } = this.props;
    if (img.editMode) {
      this.setEditMode();
    }
    if (mayAddToCurations) {
      if (curations instanceof Array) {
        let matchedCurations = curations
          .filter(cur => {
            let isIn = false;
            if (cur.images) {
              isIn =
                cur.images.filter(
                  ims => ims.image.toString() === img._id.toString()
                ).length > 0;
            }
            return isIn;
          })
          .map(cur => cur._id);

        this.setState({
          curations: matchedCurations
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.escaped !== this.props.escaped) {
      this.setState({ showXL: false, editable: false });
    }
  }

  setEditMode = () => {
    if (!this.state.editMode) {
      this.setState({
        editMode: true
      });
    }
  };

  updateImage = (data, user, close = true) => {
    this.props.updateImageData(data, user);
    if (close !== false) {
      this.setState({
        editMode: false
      });
    }
  };

  isInCuration = curation => {
    return curation instanceof Object && this.state.curations.indexOf(curation._id) >= 0;
  };

  statusHint = curation => {
    const obj = curation instanceof Object ? curation : { mode: '' };
    const { mode } = obj;
    return this.isInCuration(curation) ? `Remove from ${mode}` : `Add to ${mode}`;
  };

  addToCuration = curId => {
    let imgId = this.props.img._id;
    let { curations } = this.state;
    let curIndex = curations.indexOf(curId);
    let remove = curIndex >= 0;

    addRemoveImageToCuration(curId, imgId, remove).then(data => {
      let changed = false;
      let diff = 0;
      if (!remove && data.added) {
        curations.push(curId);
        changed = true;
        diff++;
      } else if (remove && data.removed) {
        curations.splice(curIndex, 1);
        diff--;
        changed = true;
      }
      if (changed) {
        this.setState({
          curations: curations
        });
        this.props.updateCurations(curId, imgId, diff);
      }
    });
  };

  fileName = (img) => {
    if (img.uri) {
      return img.publicId.split('/').pop() + '.jpg'
    }
  }

  downloadSize = (img) => {
    if (img.uri) {
      return `Download full image: ${img.width} x ${img.height} px`
    }
  }

  toggleXL = e => {
    e.stopPropagation();
    let { showXL } = this.state;
    let newVal = showXL !== true;
    this.setState({
      showXL: newVal
    });
    addBodyClass("full-mode", newVal);
  }

  toggleClaimApproved = (artist) => {
    const { img } = this.props;
    const _id = img instanceof Object ? img._id : '';
    if (notEmptyString(_id, 12) && artist instanceof Object) {
      const newStatus = artist.approved !== true;
      saveClaimApproved(_id, newStatus).then(result => {
        if (result) {
          const { artist } = img;
          if (artist instanceof Object) {
            artist.approved = result.status;
            this.props.updateImageData({_id, artist });
          }
        }
      })
    }
  }

  handleDrag = (e, d) => {
    let { x, y } = this.state.position;
    let bounds = document
      .querySelector(".main-images figure.active picture.xl img")
      .getBoundingClientRect();
    let nx = x + d.x;
    let ny = y + d.y;
    let maxNy = (bounds.height - window.innerHeight) / 2;
    let maxNx = (bounds.width - window.innerWidth) / 2;
    if (nx > maxNx) {
      nx = maxNx;
    } else if (nx < 0 - maxNx) {
      nx = 0 - maxNx;
    }
    if (ny > maxNy) {
      ny = maxNy;
    } else if (ny < 0 - maxNy) {
      ny = 0 - maxNy;
    }
    this.setState({
      position: {
        x: nx,
        y: ny
      }
    });
  };

  approvedIcon(approved) {
    return approved ? "thumbs up" : "thumbs down";
  }

  render() {
    const {
      img,
      sizes,
      xlSizes,
      editStatus,
      tags,
      curations,
      mayAddToCurations,
      className
    } = this.props;
    const { editMode, showXL, position } = this.state;
    let hasUser = false;
    if (img.user) {
      if (img.user.displayName) {
        hasUser = true;
      }
    }
    const actionClassNames = ["actions", img.status].join(" ");
    const approved = img.status === 'approved'
    const highlighted = img.highlighted === true
    const mayPromote = approved && !highlighted;
    const mayDemote = approved && highlighted;
    const active = img.className === "active";
    const imgId = extractId(img);
    /* const embedInfo = validPlayerUrlType(img.embed);
    const hasEmbed = embedInfo.valid; */
    const userObj = hasUser ? img.user : { embeds: [] };
    const embeds = extractEmbedsFromUser(userObj, imgId);
    const hasEmbeds = embeds.length > 0;
    const embedItems = embeds.map((row, ri) => {
      const { uri, title, approved } = row;
      const key = ['embed-item', ri].join('-')
      const { valid, type, provider } = validPlayerUrlType(uri);
      return { uri, title, key, approved, provider, valid, type };
    });
    const { hasGeo, hasPlacename, placename, coordStr } = extractPlacenameAndGeo(img);
    const userLoc = extractPlacenameAndGeo(img.user);
    const cls = [className];
    if (editMode) {
      cls.push("edit-mode");
    }
    if (showXL) {
      cls.push("show-xl");
    }
    if (notEmptyString(img.artist.name)) {
      cls.push("is-claimed");
    }
    const hasOrg = hasUser ? notEmptyString(img.user.orgName, 2) : false;
    const wrapperClassNames = cls.join(' ');
    const hasUrls = img.urls instanceof Array ? img.urls.length > 0 : false;
    const urls = hasUrls ? img.urls.map((row, ri) => {
      const { uri, type } = row
      const key = ['url', type, ri].join('-');
      return { uri, type, key };
    }) : [];
    const { artist } = img;
    const hasArtist = notEmptyString(artist.name);
    const hasArtistEmail = hasArtist && notEmptyString(artist.email);
    const hasArtistOrg = hasArtist && notEmptyString(artist.orgName);
    const hasArtistCaption = hasArtist && notEmptyString(artist.caption);
    const toggleApprovedMsg = hasArtist && artist.approved ? "Remove approval" : "Approve";
    return (
      <figure className={wrapperClassNames}>
        {active && editMode && (
          <ImageEdit
            img={img}
            tags={tags}
            updateImage={this.updateImage.bind(this)}
          />
        )}
        <Picture img={img} sizes={sizes} className="large" />
        {showXL && (
          <Rnd position={position} onDragStop={this.handleDrag}>
            <Picture img={img} sizes={xlSizes} className="xl" />
          </Rnd>
        )}
        <div className="show-xl mid-overlay" onClick={this.toggleXL}>
          <Icon className="close" />
        </div>
        <div className={actionClassNames}>
          <Icon
            className="thumbs up"
            onClick={() => editStatus(img._id, "approved")}
            title="Approve"
          />
          <Icon
            className="hand point right outline"
            onClick={() => editStatus(img._id, "referred")}
            title="Refer"
          />
          <Icon
            className="thumbs down"
            onClick={() => editStatus(img._id, "rejected")}
            title="Reject"
          />
          {mayPromote && (
            <Icon
              className="star outline"
              onClick={() => editStatus(img._id, "promote")}
              title="Promote this image"
            />
          )}
          {mayDemote && (
            <Icon
              className="star"
              onClick={() => editStatus(img._id, "unpromote")}
              title="Do not promote"
            />
          )}
          {img.mayDelete && (
            <Icon
              className="trash"
              onClick={() => editStatus(img._id, "delete")}
              title="Delete"
            />
          )}
          <div className="status">{img.status}</div>
          {img.likes > 0 && <div className="num-likes" title="# of likes"><Icon className="heart" />{img.likes}</div>}
        </div>
        <figcaption>
          {img.tags && (
            <ul className="tags">
              {img.tags.map(t => (
                <li key={t._id}>{t.name}</li>
              ))}
            </ul>
          )}
          <p className="caption">{img.caption}</p>

          {img.hasDescription && (
            <div className="description">{renderHTML(img.description)}</div>
          )}
          {hasPlacename && (
            <p className="location">
              <Icon className="map marker alternate" />
              {placename}
              {hasGeo && <span className="coordinates"><span className="text-label">{coordStr}</span></span>}
            </p>
          )}
          {hasUser && (
            <p className="user-display-name">{img.user.displayName}</p>
          )}
          {hasOrg && (
            <p className="user-display-name">{img.user.orgName}</p>
          )}
          {userLoc.hasPlacename && (
            <p className="location">
              <Icon className="map marker alternate" />
              {userLoc.placename}
              {userLoc.hasGeo && <span className="coordinates"><span className="text-label">{userLoc.coordStr}</span></span>}
            </p>
          )}
          {hasUrls && <ul className="urls">{urls.map(row => <li key={row.key} className={row.type}><a href={row.uri} className="url" target="_blank" title="Preview in new tab">{row.uri}</a></li>)}</ul>}
          {hasEmbeds && <ul className="embeds">{embedItems.map(row => <li key={row.key} className="media-embed"><a href={row.uri} className="open-media" target="_blank" title="Preview in new tab">{row.valid && <Icon className="video" title="Open media" />}{row.approved && <Icon className="thumbs up" title="Approved" />}{row.title}</a></li>)}</ul>}
          <p className="created datetime">Uploaded: {moment(img.created).format("DD/MM/YYYY HH:mm")}</p>
          {hasArtist && <dl className="artist twin-column">
              <dt>Claimed by:</dt>
              <dd>{artist.name}</dd>
              {hasArtistEmail && <dt>Email:</dt>}
              {hasArtistEmail && <dd>{artist.email}</dd>}
              {hasArtistOrg &&<dt>Organisation:</dt>}
              {hasArtistOrg && <dd>{artist.orgName}</dd>}
              {hasArtistCaption && <dt>Caption:</dt>}
              {hasArtistCaption &&<dd>{artist.caption}</dd>}
              <dt>Approved</dt>
              <dd><span className="toggle-approve" title={toggleApprovedMsg} onClick={e => this.toggleClaimApproved(artist)}><Icon className={this.approvedIcon(artist.approved)} /></span></dd>
            </dl>}
          <Icon
            className="edit outline"
            onClick={this.setEditMode}
            title="Edit"
          />
          <Icon className="cloud download">
            <a href={img.uri} target="_blank" title={this.downloadSize(img)} download={this.fileName(img)}></a>
          </Icon>
        </figcaption>
        {mayAddToCurations && (
          <ul className="curations">
            {curations.map(cur => (
              <li key={cur._id} onClick={() => this.addToCuration(cur._id)} className={cur.className} title={this.statusHint(cur, img._id)}>
                <span className="title">{cur.title}</span>
                <em className="num">{cur.images.length}</em>
                {this.isInCuration(cur) && <Icon className="check" />}
              </li>
            ))}
          </ul>
        )
        }
      </figure>
    );
  }
}

export default MainPhoto;
