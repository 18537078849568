import React from "react";
import { Segment, Container, Table, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { fetchCurations, fetchUser, deleteCuration } from "../../modules/api";
import { Link } from "react-router-dom";

class CurationList extends React.Component {
  state = {
    curations: [],
    numCurations: 0,
    currUser: null,
  };

  async componentDidMount() {
    let user = await fetchUser();
    const { folderMode } = this.props;
    if (user) {
      this.setState({
        currUser: user,
      });
      this.loadCurations();
    }
  }

  loadCurations = async () => {
    const { folderMode } = this.props;
    const user = await fetchUser();
    const type = folderMode ? 'folder' : 'curation';
    let data = await fetchCurations(type);
    switch (user.role) {
      case "admin":
      case "selector":
        break;
      default:
        let uid = user._id.toString();
        data.items = data.items.filter(cu => cu.user._id.toString() === uid);
        break;
    }
    if (data.items) {
      this.setState({
        curations: data.items,
        numCurations: data.items.length
      });
    }
  };

  deleteCuration = curId => {
    deleteCuration(curId).then(data => {
      if (data.valid) {
        let editedCurations = this.state.curations.filter(c => c._id !== curId);
        this.setState({
          curations: editedCurations,
          numCurations: editedCurations.length
        });
      }
    });
  };

  isFolderMode() {
    const { pathname } = window.location;
    return pathname.includes('folders');
  }

  render() {
    const { curations, numCurations } = this.state;
    const { folderMode } = this.props;
    const curationMode = !folderMode;
    const objectTitle = folderMode ? "Folders" : "Curations";
    const pageTitle = `Edit ${objectTitle}`;
    const buildLink = (id = '') => {
      const first = folderMode ? 'folder' : 'curation';
      const parts = [first, 'edit', id];
      return '/' + parts.join('/')
    }
    return (
      <Container className="curations-list">
        <Segment.Group>
          <h2>{pageTitle}</h2>
          <div className="actions">
            <Link to={buildLink("new")}>
              <Icon className="add" />
            </Link>
          </div>
          {numCurations > 0 && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Curator</Table.HeaderCell>
                  <Table.HeaderCell># images</Table.HeaderCell>
                  <Table.HeaderCell>Edit</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {curations.map((cur, index) => (
                  <Table.Row key={index}>
                    <Table.HeaderCell>{cur.title}</Table.HeaderCell>
                    <Table.HeaderCell>{cur.user.displayName}</Table.HeaderCell>
                    <Table.Cell>
                      {cur.numSelected} / {cur.num}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={buildLink(cur._id)}>
                        <Icon className="edit" />
                      </Link>
                      {cur.images.length < 1 && (
                        <Icon
                          className="delete"
                          onClick={() => this.deleteCuration(cur._id)}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Segment.Group>
      </Container>
    );
  }
}
CurationList.propTypes = {
  folderMode: PropTypes.bool,
};

CurationList.defaultProps = {
  folderMode: false,
};

export default CurationList;
