import React from 'react';
import PropTypes from "prop-types";
import { Icon, Input } from 'semantic-ui-react';
import { buildClassNames, notEmptyString } from '../../modules/utils';
import { validPlayerUrlType } from "../../media-validator";

class EmbedItem extends React.Component {

  state = {
    itemUri: '',
    itemTitle: '',
    itemApproved: false,
    msg: '',
    valid: true
  }

  componentDidMount() {
    const { uri, title, approved } = this.props
    this.setState({
      itemUri: uri,
      itemTitle: title,
      itemApproved: approved === true
    })
  }

  onChange = (e, element) => {
    const { value, name } = element;
    switch (name) {
      case 'title':
        this.setState({ itemTitle: value });
        break
      case 'uri':
        this.setState({ itemUri: value });
        break
      case 'approved':
        this.setState({ itemApproved: value });
        break
    }
    const { update, index } = this.props;
    if (name === 'uri') {
      const info = validPlayerUrlType(value);
      const msg = info.valid ? [info.type, info.provider].join(': ') : "";
      this.setState({ msg, valid: info.valid })
    }
    setTimeout(() => {
      if (update instanceof Function) {
        const { itemUri, itemTitle, itemApproved } = this.state
        update({ uri: itemUri, title: itemTitle, approved: itemApproved !== false, index })
      }
    }, 250);
  }

  updateStatusValue = () => {
    const { itemApproved } = this.state;
    this.setState({ itemApproved: itemApproved !== true });
  }


  completeUri = () => {
    const { itemUri, itemTitle, itemApproved } = this.state
    const { index } = this.props
    let uri = itemUri;
    if (notEmptyString(uri) && notEmptyString(itemTitle)) {
      uri = uri.replace(/^(https?):\/\w+/, '$1://')
      if (!/^https?:\/\//.test(uri)) {
        uri = ['https', uri].join('://')
      }
      this.setState({ itemUri: uri });
      const { update } = this.props;
      if (update instanceof Function) {
        update({ uri, title: itemTitle, approved: itemApproved !== false, index })
      }
    }
  }

  remove = () => {
    const { update, index } = this.props;
    if (update instanceof Function) {
      update({ remove: index })
    }
  }

  render() {
    let { itemTitle, itemUri, itemApproved, msg, valid } = this.state;
    const { index } = this.props;
    const wrapperClass = buildClassNames(["embed-selector", ['item', index].join('-')]);
    const cls = ["square", "outline"];
    if (itemApproved) {
      cls.unshift("check");
    }
    const statusIconClassNames = cls.join(" ");
    const hasEmbed = notEmptyString(itemUri, 5);
    const embedIcon = valid ? 'check' : 'question';
    return (
      <div className={wrapperClass} onMouseLeave={this.completeUri}>
        <Input name='title' type='text' defaultValue={itemTitle} onChange={this.onChange} className="title" placeholder="Media title">
        </Input>
        <Input name='uri' type='url' defaultValue={itemUri} onChange={this.onChange} className="uri" placeholder="Full media URL">
          <input />
          <Icon
            className={statusIconClassNames}
            onClick={this.updateStatusValue}
          />
          <Icon className="trash" onClick={this.remove} title="Delete Embed" />
        </Input>
        {hasEmbed && <p className="embed-message"><Icon className={embedIcon} />{valid ? (<a href={itemUri} className="open-media" target="_blank" title={itemTitle}>{itemTitle}</a>) : (<span className="error">{msg}</span>)}</p>}
      </div>
    )
  }
}

EmbedItem.propTypes = {
  title: PropTypes.string,
  uri: PropTypes.string,
  image: PropTypes.string,
  update: PropTypes.func,
  index: PropTypes.number,
  approved: PropTypes.bool
};

EmbedItem.defaultProps = {
  title: "",
  uri: "",
  approved: false,
  index: 0,
};

export default EmbedItem;
