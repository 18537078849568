import config from '../config/config'


export const isNumeric = (num) => {
	let dt = typeof num;
	if (num !== null && num !== undefined && (dt === 'number' || dt === 'string')) {
		return !isNaN(parseFloat(num)) && isFinite(num);	
	} else {
		return false;
	}
}

export const smartCastNumber = (item = null, defVal = 0, isInt = false) => {
  let out = defVal;
  if (typeof item === 'string') {
    if (item.length > 0) {
      if (isNumeric(item)) {
        out = isInt ? parseInt(item, 10) : parseFloat(item);
      }
    }
  } else if (typeof item === 'number') {
    out = item;
  }
  return out;
}

export const smartCastFloat = (item = null, defVal = 0) => {
  return smartCastNumber(item, defVal, false);
}

export const notEmptyString = (str, minLength = 0) => {
	if (typeof str === 'string') {
		if (!minLength) {
			minLength = 0;
		}
		return str.trim().length > minLength;
	}
	return false;
}
  
export const extractFromTagsRef = (refElement) => {
	if (refElement instanceof Object) {
		const { current } = refElement;
		const state = current instanceof Object ? current.state : null;
		const hasState = state instanceof Object;
		const query =  hasState ? state.query : null;
		return { query, state, hasState };
	} else {
		return { query: '', state: null, hasState: false };
	}
}

export const matchInt = (param, defaultValue) => {
	if (!isNumeric(param)) {
    return defaultValue;
  } else {
    return parseInt(param);
  }
}

export const sanitize = (str = "") => {
	let out = "";
	if (typeof str === "string") {
		out = str
			.trim()
			.toLowerCase()
			.replace(/[^a-z0-9-]+/g, "-")
			.replace(/-$/, '');
	}
	return out;
};

export const addBodyClass = (className, addMode) => {
	const cl = document.body.classList
	const exists = cl.contains(className)
	if (addMode !== false) {
		if (!exists) {
			cl.add(className)
		}
	} else {
		if (exists) {
			cl.remove(className)
		}
	}
}

export const removeBodyClass = (className) => {
	addBodyClass(className, false)
}

export const hasBodyClass = (className) => {
	const cl = document.body.classList
	return cl.contains(className)
}

export const buildClassNames = (classNames = null, conditions =  null) => {
	const isArray = classNames instanceof Array;
	const isString = isArray ? false : typeof classNames === 'string';
	let cls = isArray ? classNames : isString ? [classNames] : [];
	if (conditions instanceof Object) {
		cls = cls.concat(Object.entries(conditions).filter(entry => entry[1] === true).map(entry => entry[0]));
	}
	return cls.join(" ");
}

export const transformLocal = (img, params) => {
  const { width, height } = params
  const parts = img.uri.split('.');
  const ext = parts.pop();
  const baseImgUri = config.mediaBaseUrl.replace(/\/$/, '') + parts.join('.')
  return `${baseImgUri}--${width}--${height}.${ext}?t=${width}`
}

export const transformCloudinary = (img, cloudinary, params) => {
  const { width, height, crop, quality } = params
  return `${cloudinary.baseUrl}/${
    cloudinary.widget.cloudName
    }/image/upload/f_auto,q_auto/c_${crop},h_${height},q_${quality},w_${width}/v1/${
    img.publicId
    }.jpg`
}

export const validUrl = (url = "") => {
	if (typeof url === "string") {
		return /https?:\/\/\w+[^ ]*?\.\w[^ ]*?$/.test(url.trim())
	}
	return false;
}


const UKPostcodeRegex = /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/i;

export const validUKPostcode = postcode => {
	return UKPostcodeRegex.test(postcode);
}

export const extractPostcode = (address = '') => {
	const m = address.match(UKPostcodeRegex);
	if (m) {
		return m[0];
	} else {
		return address;
	}
}

export const validCoords = (coords = null) => {
	if (coords instanceof Object) {
		const {lat, lng } = coords;
		if (isNumeric(lat) && isNumeric(lng)) {
			return smartCastFloat(lat) !== 0 && smartCastFloat(lng) !== 0;
		}
	}
	return false;
}

const asFixedNumber = (num = 0, places = 3, absolute = false) => {
	const refNum = absolute ? Math.abs(num) : num;
	const [whole, decPlaces] = refNum.toFixed(places).split('.');
	const frac = decPlaces.replace(/0*?$/, '');
	if (frac.length < 1) {
		return whole;
	} else {
		return [whole, frac].join('.');
	}
}

const asFixedAbsNumber3 = (num = 0) => {
	return asFixedNumber(num, 3, true);
}

export const latLngFormatter = (coords = null) => {
	if (coords instanceof Object) {
		const {lat, lng } = coords;
		const latFl = smartCastFloat(lat);
		const lngFl = smartCastFloat(lng);
		if (!isNaN(latFl) && !isNaN(lngFl)) {
			const nsLetter = latFl >= 0 ? 'N' : 'S';
			const ewLetter = lngFl >= 0 ? 'E' : 'W';
			return `${asFixedAbsNumber3(latFl)}º${nsLetter}, ${asFixedAbsNumber3(lngFl)}º${ewLetter}`;
		}
	} else {
		return '';
	}
}

export const extractPlacenameAndGeo = (parentObj =  null) => {
	let hasGeo = false;
	let hasPlacename = false;
	let placename = '';
	let geo = {lat : 0, lng: 0};
	let coordStr = '';
	if (parentObj instanceof Object) {
		if (Object.keys(parentObj).includes("location") && parentObj.location instanceof Object) {
			const keys = Object.keys(parentObj.location);
			if (keys.includes("placename")) {
				placename = parentObj.location.placename;
				if (notEmptyString(placename, 16)) {
					placename = placename.replace(/(,\s*)?\([A-Z]{1,3}\d[^)]{0,6}\)\s*$/, '').trim()
				}
				hasPlacename = true
				if (keys.includes("lat") && keys.includes("lng")) {
					const { lat, lng } = parentObj.location
					geo = { lat: smartCastFloat(lat), lng: smartCastFloat(lng) }
					const invalid = lat === 0 && lng === 0
					if (!invalid) {
						coordStr = latLngFormatter(geo)
						hasGeo = true
					}
				}
			}
		}
	}
	return { hasPlacename, hasGeo, placename, geo, coordStr }
}


export const extractId = (img = null) => {
	if (img instanceof Object) {
		if (img._id) {
			return img._id.toString();
		}
	}
	return "";
}

export const extractEmbedsFromUser = (user = null, imgId = '') => {
	if (user instanceof Object) {
		const filterByImgId = notEmptyString(imgId, 12);
		if (user.embeds instanceof Array && user.embeds.length > 0) {
			return user.embeds.filter(em => em instanceof Object).filter(em => {
				if (filterByImgId) {
					if (em.image) {
						return em.image.toString() === imgId;
					} else {
						return false;
					}
				} else {
					return true;
				}
			});
		}
	}
	return [];
}

export const extractEmbeds = (img = null) => {
	if (img instanceof Object) {
		if (img.user) {
			return extractEmbedsFromUser(img.user);
		}
	}
	return [];
}

export const workHasEmbeds = (img = null) => {
	const imgId = extractId(img);
	return extractEmbeds(img).some(em => {
		if (em.image) {
			return em.image.toString() === imgId;
		} else {
			return false;
		}
	});
}

export const extractImageEmbeds = (img = null) => {
	const imgId = extractId(img);
	return extractEmbeds(img).filter(em => {
		if (em.image) {
			return em.image.toString() === imgId;
		} else {
			return false;
		}
	});
}

export const mapArtist = (obj) => {
	const artist = obj instanceof Object ? obj : { name: '', email: '', orgName: '', capion: '', approved: false };
	return {
		name: notEmptyString(artist.name) ? artist.name : "",
		email: notEmptyString(artist.email) ? artist.email : "",
		orgName: notEmptyString(artist.orgName) ? artist.orgName : "",
		caption: notEmptyString(artist.caption) ? artist.caption : "",
		approved: artist.approved === true
	}
}