import React from "react";
import { Container, Image } from "semantic-ui-react";
import { fetchUser, authenticate } from "../../modules/api";

class Login extends React.Component {

  _mounted = false;

  state = {
    id: 0,
    loggedIn: false,
    title: "Admin only",
    subtitle: "Log In",
    mainImage:
      "http://4.bp.blogspot.com/-s4ooG4OH1SA/URvPwb6gosI/AAAAAAAAAN8/wNuyMN-EXcc/s1600/Photomontage-Egg-location-Oct-2012.jpg",
    text: "Enter your email name and password",
    email: "",
    password: "",
    showError: false,
    errorMsg: ""
  };

  componentDidMount = async () => {
    this._mounted = true;
    let matchedUser = await fetchUser();
    if (matchedUser) {
      if (matchedUser.identifier) {
        this.setUserData(matchedUser, false);
      }
    }
  };

  componentWillUnmount() {
    this._mounted = false;
  }

  setUserData = matchedUser => {
    if (this._mounted) {
      const params = {
        id: matchedUser._id,
        status: matchedUser.status,
        loggedIn: true,
        identifier: matchedUser.identifier
      };
      this.setState(params);
      this.props.registerLogin();
    }
  };

  updateValue = e => {
    const { name, value } = e.target;
    if (name && this._mounted) {
      let params = {};
      params[name] = value;
      this.setState(params);
    }
  };

  handleLogin = async () => {
    let user = {};
    if (this._mounted) {
      const { email, password } = this.state;
      this.setState({
        showError: false
      });
      await authenticate(email, password).then(data => (user = data));

      if (user.identifier) {
        this.setUserData(user, true);
      } else if (user.valid === false) {
        this.setState({
          showError: true,
          errorMsg: "Incorrect user name or password"
        });
      }
    }
  };

  userData = () => {
    return this.state;
  };

  render() {
    let {
      title,
      subtitle,
      mainImage,
      text,
      email,
      password,
      errorMsg,
      showError
    } = this.state;
    let loginButtonClassName =
      /^[^@]+@\w+/.test(email) && password.length > 5 ? "active" : "inactive";
    return (
      <Container className="main-content">
        <section className="main-area">
          <h2 className="subtitle">{subtitle}</h2>
          <Image src={mainImage} size="medium" className="large" />
        </section>
        <aside className="text-content">
          <h1 className="title">{title}</h1>
          <div className="login-form">
            <span className="login-fields">
              <input
                name="email"
                type="email"
                minLength={6}
                size={32}
                placeholder="Admin email"
                required
                className="textfield textfield-medium"
                onChange={this.updateValue}
              />
              <input
                name="password"
                type="password"
                minLength={6}
                size={16}
                placeholder="Password"
                required
                className="textfield textfield-short"
                onChange={this.updateValue}
              />
              <button
                onClick={this.handleLogin}
                className={loginButtonClassName}
              >
                Log in
              </button>
            </span>
            {showError && <p className="error">{errorMsg}</p>}
          </div>

          <article>{text}</article>
        </aside>
      </Container>
    );
  }
}

export default Login;
