import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Gallery from "../gallery/index";
import Landing from "../landing/index";
import TagList from "../tags/index";
import LikesList from "../likes/index";
import CurationList from "../curations/index";
import CurationEdit from "../curations/edit";
import UserList from "../user/index";
import UserWidget from "../user/widget";
import MenuList from "../elements/menu-list";
import Footer from "../elements/footer";
import Login from "../user/login";
import { fetchUser } from "../../modules/api";
import config from "../../config/config";

class App extends React.Component {
  state = {
    loggedIn: false,
    title: "Portrait of an Egg: Moderation",
    menuItems: [],
    footerData: {
      title: "Developed by",
      subtitle: "Platform 3",
      subtitleLink: "https://www.platform-3.co.uk"
    },
    user: {
      _id: "",
      role: ""
    }
  };

  componentDidMount = async () => {
    let matchedUser = await fetchUser();
    let loggedIn = matchedUser.loggedIn;
    if (loggedIn) {
      this.setState({
        loggedIn: true,
        user: matchedUser
      });
    }
    this.processMenuItems(loggedIn, matchedUser);
  };

  registerLogin = async (out = false) => {
    let matchedUser = { _id: "", role: "" };
    if (out !== true) {
      out = false;
      matchedUser = await fetchUser();
    }
    this.setState({
      loggedIn: !out,
      user: matchedUser
    });

    this.processMenuItems(!out, matchedUser);
    if (!out) {

      switch (matchedUser.role) {
        case 'curator':
          if (this.props.location.pathname.length > 3 && !/^\/?(likes|curations|curation\/edit)/.test(this.props.location.pathname)) {
            this.props.history.push('/curations')
          }
        case 'selector':
          if (this.props.location.pathname.length > 3 && !/^\/?(likes|images|curations|tags)/.test(this.props.location.pathname)) {
            this.props.history.push('/images');
          }
      }
    }
  };

  registerLogout = () => {
    this.registerLogin(true);
  };

  processMenuItems = (loggedIn, user) => {
    let menuItems = config.mainMenuItems
      .filter(item => {
        let valid = false;
        if (user) {
          if (user.role) {
            if (item.roles.indexOf(user.role) >= 0) {
              valid = true;
            }
          }
        }
        if (!valid) {
          valid = item.roles.indexOf("all") >= 0;
        }
        return valid;
      })
      .map(item => {
        item.hasContent = typeof item.content === "string";
        item.hasLabel = typeof item.label === "string";
        item.hasLoggedinLabel = typeof item.loggedinLabel == "string";
        item.hasIcon = typeof item.icon === "string";
        return item;
      });
    this.setState({ menuItems: menuItems });
  };

  matchPerms = () => {
    const { user } = this.state;
    const perms = {
      mayEditUsers: false,
      mayAddCurations: false,
      mayManageCurations: false,
      mayManageFolders: false
    };
    switch (user.role) {
      case "admin":
        perms.mayEditUsers = true;
        perms.mayAddCurations = true;
        perms.mayManageCurations = true;
        perms.mayManageFolders = true;
        break;
      case "selector":
        perms.mayAddCurations = true;
        perms.mayManageCurations = true;
        perms.mayManageFolders = true;
        break;
      case "curator":
        perms.mayManageCurations = true;
        break;
    }
    return perms;
  };

  render() {
    let { loggedIn, user, title, menuItems, footerData } = this.state;
    const { location } = this.props;
    const current = location.pathname;
    const cls = ["main-wrapper", "wrapper"];
    let pathParts = current.replace(/^\//, "").split("/");
    let pathSlug = pathParts.join("-");
    let section = pathParts.shift();
    const perms = this.matchPerms();
    if (section) {
      cls.push(section);
    }
    cls.push(pathSlug);
    if (loggedIn) {
      cls.push("logged-in");
    }
    let wrapperClassNames = cls.join(" ");
    cls.shift();
    cls.unshift("top-wrapper");
    const topWrapperClassNames = cls.join(" ");
    return (
      <div className="outer">
        <div className={topWrapperClassNames}>
          <header className="inner-wrapper">
            <figure className="logo">
              <figcaption>{title}</figcaption>
            </figure>
            <nav className="main-nav">
              <MenuList
                items={menuItems}
                current={current}
                className="main-menu"
                loggedIn={loggedIn}
              />
              {loggedIn && <UserWidget registerLogout={this.registerLogout} />}
            </nav>
          </header>
        </div>
        <div className={wrapperClassNames}>
          <main className="inner-wrapper">
            {loggedIn ? (
              <Switch>
                <Route exact path="/" component={Landing} />
                {perms.mayAddCurations && (
                  <Route
                    exact
                    path="/images/:status?/:category?"
                    component={Gallery}
                  />
                )}
                {perms.mayAddCurations && (
                  <Route exact path="/tags" component={TagList} />
                )}
                {perms.mayManageCurations && (
                  <Route exact path="/likes" component={LikesList} />
                )}
                {perms.mayManageCurations && (
                  <Route exact path="/curations" component={CurationList} />
                )}
                {perms.mayManageCurations && (
                  <Route
                    exact
                    path="/curation/edit/:ref"
                    component={CurationEdit}
                  />
                )}
                {perms.mayManageFolders && (
                  <Route exact path="/folders" component={() => <CurationList folderMode={true} />} />
                )}
                {perms.mayManageFolders && (
                  <Route
                    exact
                    path="/folder/edit/:ref"
                    component={CurationEdit}
                  />
                )}
                {perms.mayEditUsers && (
                  <Route
                    exact
                    path="/users/:mode?/:uid?"
                    component={UserList}
                  />
                )}
              </Switch>
            ) : (
              <Route
                path="*"
                component={() => <Login registerLogin={() => this.registerLogin(false)} />}
              />
            )}
          </main>
          <Footer data={footerData} />
        </div>
      </div>
    );
  }
}

export default withRouter(App);
