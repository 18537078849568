import React from "react";
import { Link } from "react-router-dom";
import { Segment, Container, Icon } from "semantic-ui-react";
import renderHTML from 'react-render-html';
import {
  fetchCuration,
  fetchUser,
  fetchUsers,
  saveCuration,
  extractPublicId,
  fetchTags
} from "../../modules/api";
import { initCloudinary, openWidget } from "../../modules/uploader";
import Photo from "../elements/photo";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
  arrayMove
} from "react-sortable-hoc";
import PictureList from "../elements/picture-list";
import { extractFromTagsRef, notEmptyString } from "../../modules/utils";
import ReactTags from "react-tag-autocomplete";

const DragHandle = sortableHandle(() => (
  <div className="handle">
    <Icon className="arrows alternate vertical" />
  </div>
));

const SortableItem = SortableElement(
  ({ imageSet, selectImage, highlightImage, index }) => (
    <li className={imageSet.highlightedClassNames}>
      <Photo
        img={imageSet.image}
        width={640}
        height={640}
        className="sortable-image"
        onClick={e => highlightImage(imageSet._id)}
      />
      <DragHandle />
      <Icon
        className={imageSet.selectedClassNames}
        onClick={e => selectImage(imageSet._id)}
      />
      <Icon
        className={imageSet.zoomClassName}
        onClick={e => highlightImage(imageSet._id)}
      />
      <div className="info">
        <p className="caption">{imageSet.image.caption}</p>
        {imageSet.image.description.length > 2 && (
          <div className="description">{renderHTML(imageSet.image.description)}</div>
        )}
        {imageSet.image.hasLocation && (
          <p className="location">{imageSet.image.location.placename}</p>
        )}
        <p className="user-name">{imageSet.image.user.displayName}</p>
        <ul className="tags">
          {imageSet.image.tags.map((tag, ti) => (
            <li key={['curation-tag', index, ti].join('-')}>{tag.name}</li>
          ))}
        </ul>
        <div className="likes">
          <Icon className="heart" /> <span className="num-likes">{imageSet.image.likes}</span>
        </div>
      </div>
    </li>
  )
);

const SortableList = SortableContainer(
  ({ items, selectImage, highlightImage }) => {
    return <ul className="curation-images">
      {items.map((imageSet) => (
        <SortableItem
          key={`curation-item-${imageSet.index}`}
          index={imageSet.index}
          imageSet={imageSet}
          selectImage={selectImage}
          highlightImage={highlightImage}
        />
      ))}
    </ul>;
  }
);

class CurationEdit extends React.Component {
  state = {
    _id: null,
    isNew: false,
    title: "",
    text: "",
    mode: "",
    bio: "",
    user: null,
    profileUri: "",
    hasProfileUri: false,
    mainImage: {},
    images: [],
    showGrid: false,
    status: 0,
    currUser: null,
    curators: [],
    showError: false,
    errorMsgs: [],
    showMsg: false,
    msg: "",
    showUploader: false,
    selectedTags: [],
    suggestions: [],
  };

  constructor(props) {
    super(props);
    this.rTags = React.createRef();
  }

  async componentDidMount() {
    initCloudinary();
    let id = this.props.location.pathname.split("/").pop();
    this.loadCuration(id);
    let user = await fetchUser();
    if (user) {
      this.setState({
        currUser: user
      });
    }
    let usersData = await fetchUsers();
    if (usersData.items) {
      let users = usersData.items;
      let curators = users.filter(u => u.role === "curator" && u.status > 0);
      if (curators.length > 0) {
        this.setState({
          curators: curators
        });
      }
    }
    const tagData = await fetchTags();
    this.setState({
      suggestions: tagData.items,
      numTags: tagData.num
    });
  }

  isFolderMode() {
    const { pathname } = window.location;
    return notEmptyString(pathname) ? pathname.includes('folder') : false;
  }

  loadCuration = async id => {
    let data = {
      valid: false
    };
    if (typeof id === "string") {
      if (id !== "new" && id.length > 5) {
        data = await fetchCuration(id);
      }
    }
    if (data.valid) {
      if (!data.profileUri) {
        data.profileUri = "";
      }
      let { hasProfileUri, mainImage } = this.assignMainImage(
        data.profileUri,
        data.user
      );
      const mode = notEmptyString(data.mode) ? data.mode : this.isFolderMode() ? 'folder' : 'curation';
      const selectedTags = data.tags instanceof Array ? data.tags : [];
      this.setState({
        _id: data._id,
        title: data.title,
        text: data.text,
        mode,
        bio: data.bio,
        profileUri: data.profileUri,
        hasProfileUri: hasProfileUri,
        mainImage: mainImage,
        status: data.status,
        images: this.processImages(data.images),
        num: data.images.length,
        user: data.user,
        showUploader: this.mayShowUploader(data),
        selectedTags
      });
      let { currUser } = this.state;
      if (currUser) {
        switch (currUser.role) {
          case "curator":
            if (currUser._id.toString() !== this.state.user._id.toString()) {
              this.props.history.push("/curations");
            }
            break;
        }
      }
    } else {
      const mode = this.isFolderMode() ? 'folder' : 'curation';
      this.setState({ isNew: true, mode });
    }
  };

  mayShowUploader = data => {
    let hasTitle = false;
    let hasUser = false;
    let { isNew } = this.state;
    if (data.title) {
      hasTitle = typeof data.title === "string" && data.title.trim().length > 2;
    }
    if (data.user) {
      if (data.user._id) {
        hasUser = true;
      } else {
        hasUser = data.user !== null;
      }
    }
    return !isNew && hasTitle && hasUser;
  };

  assignMainImage = (profileUri, user) => {
    let hasProfileUri = profileUri.length > 5;
    let mainImage = { uri: "", title: "", source: "cloudinary" };
    if (hasProfileUri) {
      mainImage.uri = profileUri;
      mainImage.publicId = extractPublicId(profileUri);
      if (user) {
        if (user.displayName) {
          mainImage.title = user.displayName;
        }
      }
    }
    return { mainImage, hasProfileUri };
  };

  highlightImage = hlId => {
    this.setState({
      images: this.processImages(this.state.images, null, hlId)
    });
  };

  processImages = (images, toggleId, hlId) => {
    return images.map((ims, index) => {
      let cls = ["square", " outline"];
      const hasToggleId = notEmptyString(toggleId, 16);
      
      if (!ims.selected) {
        ims.selected = false;
      } else {
        ims.selected = true;
      }
      const selectAll = toggleId === 'all' && !ims.selected;
      if (hasToggleId || selectAll) {
        if (ims._id.toString() === toggleId || selectAll) {
          ims.selected = !ims.selected;
        }
      }
      if (ims.selected) {
        cls.unshift("check");
      }
      ims.selectedClassNames = cls.join(" ");
      cls = ["sortable-item"];
      const zoomed = ims.zoomed === true;
      if (hlId) {
        if (ims._id === hlId) {
          ims.zoomed = !zoomed;
          if (ims.zoomed) {
            cls.push("highlighted");
          }
        }
      } else {
        ims.zoomed = false;
      }
      if (ims.selected) {
        cls.unshift("selected");
      }
      ims.zoomClassName = ims.zoomed ? "zoom out" : "zoom";
      ims.highlightedClassNames = cls.join(" ");
      ims.index = index;
      return ims;
    });
  };

  isSelector = () => {
    let { currUser } = this.state;
    if (currUser) {
      switch (currUser.role) {
        case "admin":
        case "selector":
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  updateValue = e => {
    if (e.target.name) {
      let nm = e.target.name;
      let params = {};
      params[nm] = e.target.value;
      this.setState(params);
    }
  };

  updateStatusValue = () => {
    let { status } = this.state;
    let newVal = status === 1 ? 0 : 1;
    this.setState({ status: newVal });
  };

  openWidget = () => {
    openWidget(result => {
      if (result.info.secure_url) {
        let { user } = this.state;
        let profileUri = result.info.secure_url;
        let { hasProfileUri, mainImage } = this.assignMainImage(
          profileUri,
          user
        );
        if (hasProfileUri) {
          this.setState({
            profileUri,
            hasProfileUri: hasProfileUri,
            mainImage: mainImage
          });
          this.saveCuration();
        }
      }
    });
  };

  saveCuration = () => {
    let {
      _id,
      mode,
      title,
      text,
      bio,
      profileUri,
      hasProfileUri,
      status,
      user,
      images,
      curators,
      selectedTags
    } = this.state;
    const folderMode = this.isFolderMode();
    let params = {};
    let isNew = true;
    let valid = false;
    let errorMsgs = [];
    if (_id) {
      params._id = _id;
      isNew = false;
    }
    if (title) {
      params.title = title.trim();
    }
    if (text) {
      params.text = text.trim();
    }
    if (bio) {
      params.bio = bio.trim();
    }
    if (mode) {
      params.mode = mode;
    }
    params.status = status ? 1 : 0;

    if (user) {
      params.user = user;
      if (folderMode) {
        user = curators.find(cu => cu._id.toString() === params.user);
      }
    }
    if (!params.user) {
      if (this.state.currUser instanceof Object) {
        params.user = this.state.currUser._id;
      }
    }
    if (params.title) {
      valid = params.title.length > 1;
      if (!valid) {
        errorMsgs.push("Please add a title");
      }
    }
    if (isNew) {
      if (user) {
        if (user.role) {
          let validCurator = user.role === "curator";
          if (!validCurator) {
            errorMsgs.push("Please select a curator");
            valid = false;
          }
        }
      }
    } else if (hasProfileUri) {
      params.profileUri = profileUri;
    }
    if (valid) {
      let msg = "Saved curation details";
      if (images instanceof Array) {
        params.images = images.map(ims => {
          return {
            image: ims.image,
            selected: ims.selected
          };
        });
        params.tags = selectedTags instanceof Array ? selectedTags.map(t => t.name) : [];
        msg += " and image selection";
      }

      saveCuration(params).then(data => {
        if (data.valid) {
          if (data._id) {
            this.setState({
              _id: data._id,
              isNew: false,
              showMsg: true,
              msg: msg
            });
            setTimeout(() => {
              if (isNew) {
                window.history.replaceState(
                  {
                    id: data._id
                  },
                  "Edit curation",
                  "/curation/edit/" + data._id
                );
              }
              this.setState({
                showUploader: this.mayShowUploader(params)
              });
            }, 500);
            setTimeout(() => {
              this.setState({
                showMsg: false,
                msg: ""
              });
            }, 5000);
          }
        }
      });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ images }) => ({
      images: arrayMove(images, oldIndex, newIndex)
    }));
  };

  selectImage = imgId => {
    this.setState({
      images: this.processImages(this.state.images, imgId)
    });
  };

  setMain = image => { };

  selectTag = (tagRow) => {
    const { query, state } = extractFromTagsRef(this.rTags);
    const tagObj = tagRow instanceof Object ? tagRow : {};
    const { name, id } = tagObj;
    const { selectedTags } = this.state;
    let newTag = null;
    if (typeof name == "string") {
      state.query = ""
      newTag = {
        id: name.toLowerCase(),
        name: name.trim()
      };
    }
    if (newTag instanceof Object) {
      if (newTag.name.length > 2) {
        selectedTags.push(newTag);
        this.setState({ selectedTags });
      }
    }
  };

  handleDrag = (tag, currPos, newPos) => {
    let tags = this.state.selectedTags;
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);
    this.setState({ selectedTags: tags });
  };

  deleteTag = async tagIndex => {
    if (typeof tagIndex === "number" && tagIndex >= 0) {
      let selTags = this.state.selectedTags;
      if (tagIndex < selTags.length) {
        selTags.splice(tagIndex, 1);
        this.setState({ selectedTags: selTags });
      }
    }
  };

  hideGrid = () => {
    this.showGrid(false);
  };

  showGrid = show => {
    if (show !== false) {
      show = true;
    }
    this.setState({
      showGrid: show
    });
  };

  selectAll = () => {
    const { images } = this.state;
    if (images instanceof Array) {
      this.setState({images: this.processImages(images, 'all')})
    }
  }

  render() {
    const {
      title,
      text,
      bio,
      mainImage,
      hasProfileUri,
      status,
      images,
      isNew,
      num,
      user,
      curators,
      currUser,
      showError,
      errorMsgs,
      showMsg,
      msg,
      showUploader,
      showGrid,
      suggestions,
      selectedTags
    } = this.state;
    let userId = "";
    let selectedImages = images
      .filter(ims => ims.selected)
      .map(ims => ims.image);
    if (user) {
      if (user._id) {
        userId = user._id;
      } else if (user !== null) {
        userId = user;
      }
    }
    const cls = ["square", "outline"];
    if (status > 0) {
      cls.unshift("check");
    }
    const statusIconClassNames = cls.join(" ");
    const isSelector = this.isSelector();
    const folderMode = this.isFolderMode();
    const curationMode = !folderMode;
    const objectTitle = folderMode ? "Folder" : "Curation";
    const numImages = images instanceof Array? images.length : 0;
    const numSelected = numImages > 0 ? images.filter(row => row.selected).length : 0;
    const notAllSelected = numImages !== numSelected;
    const imageCounter = numImages > 0 ? ` (${numSelected} / ${numImages})` : '';
    const verb = isNew ? "Add new" : "Edit";
    const pageTitle = `${verb} ${objectTitle} ${imageCounter}`;
    const showUploadWidget = showUploader && curationMode;
    const rTagsClassNames = { searchWrapper: "textfield textfield-long" };
    const delimiters = ['Enter', 'Tab'];
    return (
      <Container className="curation-form">
        {showGrid ? (
          <Segment.Group>
            <div className="actions">
              <Icon
                className="sort amount down"
                onClick={this.hideGrid}
                title="Show sortable list"
              />
              <Link to="/curations">
                <Icon className="angle right" />
              </Link>
            </div>
            <div className="inner-content top-content">
              {hasProfileUri && (
                <Photo
                  img={mainImage}
                  height={200}
                  width={200}
                  crop="fit"
                  className="profile-image left"
                  title={bio}
                />
              )}
              <div className="text-content">{text}</div>
            </div>
            <PictureList
              images={selectedImages}
              setMain={this.setMain.bind(this)}
            />
          </Segment.Group>
        ) : (
          <Segment.Group>
            <h2>{pageTitle}</h2>
            <div className="actions">
              <Icon
                className="block layout"
                onClick={this.showGrid}
                title="Show preview"
              />
              <Link to="/curations">
                <Icon className="angle right" />
              </Link>
            </div>
            <div className="curation-edit-form edit-form">
              <div className="inner-wrapper">
                {showError && (
                  <ul className="error">
                    {errorMsgs.map((msg, index) => (
                      <li key={index}>{msg}</li>
                    ))}
                  </ul>
                )}
                <p className="title">
                  <input
                    name="title"
                    type="text"
                    minLength={3}
                    size={48}
                    value={title}
                    placeholder="Title"
                    required
                    className="textfield textfield-long"
                    onChange={this.updateValue}
                  />
                </p>

                {curationMode && <p className="bio">
                  <textarea
                    name="bio"
                    value={bio}
                    minLength={6}
                    cols={60}
                    rows={3}
                    placeholder="Biography"
                    required
                    className="textfield textfield-medium"
                    onChange={this.updateValue}
                  />
                </p>}
                <div className="row">
                  <ReactTags
                    ref={this.rTags}
                    tags={selectedTags}
                    delimiters={delimiters}
                    suggestions={suggestions}
                    classNames={rTagsClassNames}
                    onDelete={this.deleteTag}
                    onAddition={this.selectTag}
                    onInput={this.selectTag}
                    inline={true}
                    allowNew={true} />
                </div>
                <p className="text">
                  <textarea
                    name="text"
                    value={text}
                    minLength={6}
                    cols={60}
                    rows={4}
                    placeholder="Description"
                    required
                    className="textfield textfield-medium"
                    onChange={this.updateValue}
                  />
                </p>
                <p>
                  <span className="select-item curator">
                    {isSelector && (
                      <select
                        name="user"
                        value={userId}
                        onChange={this.updateValue}
                      >
                        {isNew && (
                          <option value="" key={0}>
                            ... select one
                          </option>
                        )}
                        {curators.map(cu => (
                          <option value={cu._id} key={cu._id}>
                            {cu.displayName}
                          </option>
                        ))}
                      </select>
                    )}
                  </span>
                  <span className="checkbox-item status">
                    <Icon
                      className={statusIconClassNames}
                      onClick={this.updateStatusValue}
                    />
                    <label
                      htmlFor="curation-status"
                      onClick={this.updateStatusValue}
                    >
                      Active
                    </label>
                  </span>
                  {notAllSelected && <span className="checkbox-item status">
                    <Icon
                      className="check square"
                      onClick={this.selectAll}
                    />
                    <label
                      htmlFor="curation-status"
                      onClick={this.selectAll}
                    >
                      Select all images
                    </label>
                  </span>}
                </p>
              </div>
              {showUploadWidget && (
                <div className="curator-image">
                  {hasProfileUri && (
                    <Photo
                      img={mainImage}
                      width={200}
                      height={200}
                      crop="fit"
                    />
                  )}
                  <button
                    id="upload-widget"
                    className="cloudinary-button"
                    onClick={() => this.openWidget()}
                  >
                    Profile Image
                    <Icon className="cloud upload" />
                  </button>
                </div>
              )}
              <div className="actions">
                <Icon className="save" onClick={this.saveCuration}>
                  <span className="label">Save</span>
                </Icon>
                {showMsg && <p className="message">{msg}</p>}
              </div>
            </div>
            {num > 0 && (
              <SortableList
                items={images}
                onSortEnd={this.onSortEnd}
                selectImage={this.selectImage}
                highlightImage={this.highlightImage}
                useDragHandle={true}
              />
            )}
          </Segment.Group>
        )}
      </Container>
    );
  }
}

export default CurationEdit;
