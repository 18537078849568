import React from "react";
import { Segment, Container, Table, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { fetchUsers, fetchArtists, saveUser } from "../../modules/api";
import moment from "moment";
import UserEdit from "../user/edit";

class UserList extends React.Component {
  state = {
    currUser: null,
    editMode: false,
    users: [],
    numUsers: 0,
    numTags: 0,
    artists: [],
    numArtists: 0,
    sortKey: 'login',
    filter: "",
    filtering: false,
    filtered: false,
    showInactive: false,
  };

  async componentDidMount() {
    this.loadUsers();
    this.loadArtists();
  }

  loadUsers = async () => {
    let data = await fetchUsers();
    if (data.items) {
      this.setState({
        users: this.filterUsers(data.items),
        numUsers: data.items.length
      });
    }
  };

  loadArtists = async () => {
    let { filter, filtering } = this.state;
    if (!filtering) {
      this.setState({
        filtering: true
      });
      let data = await fetchArtists(0, 500, filter);
      if (data.items) {
        let artists = this.filterUsers(data.items, "contributor")
        if (artists.length > 0) {
          artists = artists.sort((a,b) => {
            if (a.login > b.login) {
              return -1;
            } if (a.login === b.login) {
              return 0;
            } else {
              return 1;
            }
          })
        }
        this.setState({
          artists: artists,
          numArtists: data.items.length,
          filtering: false
        });
      }
    }
  };


  greaterThan = (a, b, sortKey) => {
    if (a.hasOwnProperty(sortKey)) {
      switch (sortKey) {
        case 'displayName':
        case 'identifier':
          return a[sortKey].trim().toLowerCase() > b[sortKey].trim().toLowerCase();
         default:
         return a[sortKey] > b[sortKey];
      }
    }
    return false;
  }

  sortArtists = async (sortKey, direction) => {
    let {artists} = this.state;
    let moreThan = -1;
    let lessThan = 1;
    if  (direction === 'asc') {
      moreThan = 1;
      lessThan = -1;
    } 
    let sorted = artists.sort((a,b) => {
      if (this.greaterThan(a, b, sortKey)) {
        return moreThan;
      } else {
        return lessThan;
      }
    });
    this.setState({
      artists: sorted,
      sortKey: sortKey
    })
  };

  mapUser = (user, role) => {
    user.hasLogin = false;
    if (user.login) {
      user.hasLogin = /^\d\d\d\d-/.test(user.login);
    }
    if (!user.hasLogin) {
      user.login = '0000-00-00 00:00:00';
    }
    if (typeof role === "string") {
      user.role = role;
    }
    let cls = ["square", "outline"];
    if (user.status > 0) {
      cls.unshift("check");
    }
    user.modeClassName = 'mail'
    switch (user.loginMode) {
      case 'google':
      case 'facebook':
        user.modeClassName = user.loginMode;
        break; 
    }
    user.activeClassNames = cls.join(" ");
    return user;
  };

  filterUsers = (users, role) => {
    return users.map(u => this.mapUser(u, role));
  };

  filterArtists = e => {
    let txt = "";
    let filter = this.state.filter;
    let filtered = false;
    if (e.target.value) {
      txt = e.target.value.trim();
      filtered = txt.length > 1;
    }
    this.setState({ filter: txt, filtering: false, filtered: txt.length > 1 });
    if (!filtered) {
      txt = "";
    }
    if (filter !== txt) {
      setTimeout(() => this.loadArtists(), 50);
    }
  };

  clearFilter = e => {
    this.setState({ filter: "", filtering: false, filtered: false });
    setTimeout(() => this.loadArtists(), 50);
  };

  saveUser = user => {
    let data = {
      valid: false
    };
    if (user) {
      saveUser(user, true).then(data => {
        data.valid = true;
        if (data.role) {
          let users = [];
          let field = "";
          switch (data.role) {
            case "contributor":
              users = this.state.artists;
              field = "artists";
              break;
            default:
              users = this.state.users;
              field = "users";
              break;
          }
          if (field && users instanceof Array) {
            let isNew = users.filter(u => u._id === data._id).length < 1;
            if (isNew) {
              users.push(data);
            } else {
              users = users.map(u => {
                if (u._id === data._id) {
                  if (field === "users") {
                    u.role = data.role;
                    u.identifier = data.identifier;
                  }
                  u.displayName = data.displayName;
                  u.status = data.status;
                }
                return this.mapUser(u);
              });
            }

            let st = {};
            st[field] = users;

            this.setState(st);
          }
        }
        this.setState({
          editMode: false
        });
      });
    }
  };

  toggleActive = user => {
    if (user) {
      let currStatus = parseInt(user.status);
      user.status = currStatus > 0 ? 0 : 1;
      this.saveUser(user);
    }
  };

  toggleShowActive = () => {
    const { showInactive } = this.state;
    this.setState({showInactive: !showInactive });
  }

  handleEdit = (user, e) => {
    if (e) {
      e.preventDefault();
    }
    let isActive = this.state.editMode;
    let ts = isActive ? 25 : 0;
    if (isActive) {
      this.closeEdit();
    }
    setTimeout(() => {
      this.setState({
        currUser: user,
        editMode: true
      });
    }, ts);
  };

  closeEdit = () => {
    this.setState({
      currUser: null,
      editMode: false
    });
  };

  rowClasses(user = null) {
    let cls = 'user-active';
    if (user instanceof Object) {
      if (user.status < 1) {
        cls = 'user-inactive';
      }
    }
    return cls;
  }

  render() {
    const {
      users,
      numUsers,
      artists,
      numArtists,
      sortKey,
      editMode,
      currUser,
      filter,
      filtered,
      showInactive,
    } = this.state;
    const cls = ["users-artists"];
    if (editMode) {
      cls.push("show-edit-form");
    }
    if (showInactive) {
      cls.push('show-inactive')
    }
    const wrapperClassNames = cls.join(" ");
    const numArtistsActive = artists.filter(a => a.status > 0).length;
    const numArtistsInactive = numArtists - numArtistsActive;
    const activeMessage = showInactive ? 'Showing all' : "Active only";
    const showInactiveToggleMsg = showInactive ? 'Hide disabled users' : "Show disabled users";
    return (
      <Container className={wrapperClassNames}>
        {editMode && (
          <UserEdit
            user={currUser}
            saveUser={this.saveUser.bind(this)}
            closeEdit={this.closeEdit}
          />
        )}
        <Segment.Group>
          <h2>Admin Users</h2>
          <div className="actions row">
            <em>{activeMessage}</em>
            <Icon className="user circle outline" onClick={this.toggleShowActive} title={showInactiveToggleMsg} />
            <Icon className="add user" onClick={this.handleEdit} />
          </div>
          {numUsers > 0 && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Display name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Role</Table.HeaderCell>
                  <Table.HeaderCell>Last logged in</Table.HeaderCell>
                  <Table.HeaderCell>Edit</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {users.map((user, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{user.displayName}</Table.Cell>
                    <Table.Cell>{user.identifier}</Table.Cell>
                    <Table.Cell>{user.role}</Table.Cell>
                    <Table.Cell>
                      {user.hasLogin &&
                        moment(user.login).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell className="actions">
                      <a href="#active" onClick={() => this.toggleActive(user)}>
                        <Icon className={user.activeClassNames} />
                      </a>
                      <a href="#edit" onClick={e => this.handleEdit(user, e)}>
                        <Icon className="edit" />
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Segment.Group>
        <Segment.Group className="artists-listing">
          <h3 class="actions">
            <span className="label"><strong>Contributors: {numArtistsActive}</strong> <small>({numArtistsInactive} inactive)</small></span>{" "}
            {filtered && (
              <em className="filter">
                {filter} <Icon className="delete" onClick={this.clearFilter} />
              </em>
            )}
            <Icon className="user circle outline" onClick={this.toggleShowActive} title={showInactiveToggleMsg} />
          </h3>
          <p className="filter">
            <input
              type="text"
              name="filter"
              onChange={this.filterArtists}
              value={filter}
              placeholder="Filter by name or email"
              className="textfield textfield-medium"
            />
          </p>
          {numArtists > 0 && (
            <Table celled className={sortKey}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="displayName sortable" onClick={() => this.sortArtists('displayName','asc')}>Display name</Table.HeaderCell>
                  <Table.HeaderCell className="identifier sortable" onClick={() => this.sortArtists('identifier','asc')}>Mode / email</Table.HeaderCell>
                  <Table.HeaderCell className="numImages sortable" onClick={() => this.sortArtists('numImages','desc')}><Icon className="picture" /></Table.HeaderCell>
                  <Table.HeaderCell className="login sortable" onClick={() => this.sortArtists('login','desc')}>Last logged in</Table.HeaderCell>
                  <Table.HeaderCell className="created sortable" onClick={() => this.sortArtists('created','desc')}>Joined</Table.HeaderCell>
                  <Table.HeaderCell>Admin</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {artists.map((user, index) => (
                  <Table.Row key={index} className={this.rowClasses(user)}>
                    <Table.Cell className="displayName">{user.displayName}</Table.Cell>
                    <Table.Cell className="identifier"><Icon className={user.modeClassName} /> {user.identifier}</Table.Cell>
                    <Table.Cell className="numImages">{user.numImages}</Table.Cell>
                    <Table.Cell className="login">
                      {user.hasLogin &&
                        moment(user.login).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell className="created">
                      {moment(user.created).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell className="actions">
                      <a href="#active" onClick={() => this.toggleActive(user)}>
                        <Icon className={user.activeClassNames} />
                      </a>
                      <Link to={`/images/all/u--${user._id}`}>
                        <Icon className="picture" />
                      </Link>
                      <a href="#edit" onClick={e => this.handleEdit(user, e)}>
                        <Icon className="edit" />
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Segment.Group>
      </Container>
    );
  }
}

export default UserList;
