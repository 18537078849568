import React from "react";
import { Container, Table, Button, Icon } from "semantic-ui-react";
import { clearMainServerCaches, getStats } from "../../modules/api";

class Landing extends React.Component {
  state = {
    title: "Image Moderation",
    subtitle: "Welcome to the Image Moderation portal",
    text: "Your are now logged in",
    statItems: [],
    clearing: false,
    msg: ''
  };
  componentDidMount = async () => {
    getStats().then(data => {
      if (data.valid) {
        this.setState({
          statItems: data.items
        });
      }
    });
  };

  clearCaches = () => {
    this.setState({clearing: true});
    clearMainServerCaches().then(result => {
      if (result.valid) {
        setTimeout(() => {
          this.setState({clearing: false});
        }, 1000);
      }
    })
  }
  
  render() {
    const { title, subtitle, text, statItems, clearing } = this.state;
    const hasStats = statItems.length > 0
    const stats = statItems.filter(stat => stat.val > 0).map(stat => {
      let txt = stat.key.replace(/-+/g, ' ')
      stat.label = txt.charAt(0).toUpperCase() + txt.slice(1);
      return stat
    })
    const clearCls = clearing ? 'disabled' : 'enabled';
    const actionClassNames = ['actions', clearCls].join(' ');
    return (
      <Container className="main-content">
        <h1 className="title">{title}</h1>
        <article className="text-content">
          <h2 className="subtitle">{subtitle}</h2>
          <div className="body">{text}</div>
          <Table className="stats" celled>
            <Table.Body>
              {hasStats &&
                stats.map((stat, index) => (
                  <Table.Row key={index}>
                    <Table.HeaderCell>{stat.label}</Table.HeaderCell>
                    <Table.Cell>{stat.val}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <div className={actionClassNames}>
            <Button onClick={this.clearCaches}>
              <Icon className="recycle" />
              <span className="text-label">Clear server caches</span>
            </Button>
          </div>
        </article>
      </Container>
    );
  }
}

export default Landing;
