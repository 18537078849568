export default {
  backendApi: '/api/',
  apiKey: 'drW3-Pd_8H-92mBw5kM',
  localStoreEnabled: false,
  mediaBaseUrl: 'https://www.thegreatimagining.earth/',
  social: {
    fbAppID: '325042438113754',
    googleAppID:
      '193669454614-uhn4lg7pdi1hchc2em6fjbs2pjhpcirp.apps.googleusercontent.com'
  },
  cloudinary: {
    widget: {
      cloudName: 'portrait-of-an-egg',
      uploadPreset: 'contributor',
      sources: ['local', 'camera', 'facebook', 'dropbox', 'instagram'],
      minImageWidth: 1024,
      minImageHeight: 640,
      instagramClientId: 'e6c07deea78c4376a39930d4a45fcbbe'
    },
    baseUrl: 'https://res.cloudinary.com',
    quality: 86
  },
  gallery: {
    perLoad: 60
  },
  imageStyles: {
    large: {
      crop: 'fit',
      sizes: [
        {
          width: 640,
          height: 640,
          max: 640
        },
        {
          width: 1280,
          height: 1280,
          min: 641,
          max: 1280,
          default: true
        },
        {
          width: 1600,
          height: 1600,
          min: 1281
        }
      ]
    },
    xlarge: {
      crop: 'fit',
      sizes: [
        {
          width: 1280,
          height: 1280,
          max: 640
        },
        {
          width: 2560,
          height: 2560,
          min: 641,
          max: 1280,
          default: true
        },
        {
          width: 3200,
          height: 3200,
          min: 1281
        }
      ]
    }
  },
  mainMenuItems: [
    { to: '/', content: 'Home', roles: ['all'] },
    { to: '/images', content: 'Images', roles: ['admin', 'selector'] },
    { to: '/tags', content: 'Tags', roles: ['admin', 'selector'] },
    { to: '/likes', content: 'Likes', roles: ['admin', 'selector', 'curator'] },
    { to: '/curations', content: 'Curations', roles: ['admin', 'selector', 'curator'] },
    { to: '/folders', content: 'Folders', roles: ['admin', 'selector'] },
    { to: '/users', content: 'Users', roles: ['admin'] },
  ]
}
