import React from "react";
import { Icon, Button } from "semantic-ui-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateImage, matchUser, saveUser } from "../../modules/api";
import ReactTags from 'react-tag-autocomplete'
import { extractEmbedsFromUser, extractFromTagsRef, extractId, extractPlacenameAndGeo, isNumeric, mapArtist, notEmptyString, validUrl } from "../../modules/utils";
import LinkItem from "../elements/link-item";
import EmbedItem from "../elements/embed-item";
import Location from "../elements/location";
// import { validPlayerUrlType } from "../../media-validator";

class ImageEdit extends React.Component {
  state = {
    currId: "",
    caption: "",
    description: "",
    placename: "",
    coords: { lat: 0, lng: 0 },
    /* embedMsg: '',
    embedValid: false, */
    topic: "",
    imageUrls: [],
    suggestions: [],
    selectedTags: [],
    artist: {
      name: '',
      orgName: '',
      email: '',
      caption: '',
      approved: false
    },
    user: {
      _id: '',
      identifier: '',
      displayName: '',
      orgName: '',
      urls: [],
      embeds: [],
      location: {
        placename: '',
        lat: 0,
        lng: 0
      }
    },
    identifier: '',
    displayName: '',
    orgName: '',
    saving: false
  };

  constructor(props) {
    super(props);
    this.rTags = React.createRef();
  }

  componentDidMount = async () => {
    const { tags, img } = this.props;
    if (img) {
      let st = {};
      if (img._id) {
        st.currId = img._id;
        if (img.caption) {
          st.caption = img.caption.replace(/^untitled\.\.+$/, '');
        }
        if (img.description) {
          st.description = img.description;
        }
        if (notEmptyString(img.topic)) {
          st.topic = img.topic;
        }
        /*         if (notEmptyString(img.embed)) {
                  st.embed = img.embed;
                  const { valid, provider, type } = validPlayerUrlType(img.embed);
                  st.embedValid = valid;
                  st.embedMsg = valid ? [type, provider].join(': ') : "invalid";
                } */
        if (img.location instanceof Object) {
          if (img.location.placename) {
            st.placename = img.location.placename;
          }
          const { lat, lng } = img.location;
          if (isNumeric(lat) && isNumeric(lng)) {
            st.coords = { lat, lng }
          }
        }
        if (img.urls instanceof Array) {
          st.imageUrls = img.urls.filter(u => u instanceof Object)
        }
        if (img.tags) {
          st.selectedTags = [];
          if (img.tags.length > 0) {
            let sTags = img.tags.filter(t => {
              if (t.hasOwnProperty('_id')) {
                return t._id.length > 5 || t._id.includes('_');
              } else {
                return false
              }
            }).map(t => {
              return { id: t._id, name: t.name };
            });
            st.selectedTags = sTags.filter(t => t.id.length > 1 && t.name.length > 1)
          }
        }

        if (img.user instanceof Object) {
          if (img.user.identifier) {
            st.user = img.user;
            st.identifier = st.user.identifier;
            st.orgName = st.user.orgName;
            st.displayName = st.user.displayName;
          }
        }
        st.artist = mapArtist(img.artist);
        if (notEmptyString(st.artist.displayName)) {
          matchUser(st.artist.email).then(result => {
            if (result instanceof Object) {
              if (notEmptyString(result.displayName)) {
                st.displayName = result.displayName;
                st.user.displayName = result.displayName;
              }
              if (notEmptyString(result.orgName)) {
                st.orgName = result.orgName;
                st.user.orgName = result.orgName;
              }
            }
          })
        }
      }
      this.setState(st);
    }
    if (tags instanceof Array) {
      const suggestions = tags.map(t => {
        return { id: t._id, name: t.name };
      });
      this.setState({
        suggestions
      });
    }
  };

  updateImageData = (close = true) => {
    this.setState({saving: true})
    const { currId, selectedTags, caption, description, placename, coords, user, topic, embed, imageUrls, artist } = this.state;
    if (currId.length > 3) {
      const data = {
        caption: caption.trim(),
        description: description.trim(),
        tags: selectedTags.map(t => t.name)
      };
      if (notEmptyString(placename)) {
        if (coords instanceof Object && Object.keys(coords).includes("lat")) {
          data.location = {
            placename: placename,
            ...coords
          };
        }

      }
      if (notEmptyString(topic)) {
        data.topic = topic
      }
      if (validUrl(embed)) {
        data.embed = embed
      }
      if (imageUrls instanceof Array) {
        data.urls = imageUrls.filter(u => u instanceof Object).filter(u => validUrl(u.uri))
      }
      if (user) {
        if (user._id) {
          data.user = user._id;
        }
      }
      if (artist instanceof Object) {
        data.artist = artist;
      }
      updateImage(currId, data, response => {
        if (response.success) {
          data._id = currId
          this.props.updateImage(data, user, close)
        }
      });
      setTimeout(() => {
        const { user } = this.state;
        if (user instanceof Object) {
          saveUser(user, true);
        }
      }, 500)
      setTimeout(() => {
        this.setState({saving: false})
      }, 500);
    }
  };

  updateLocation = (data = null, mode = 'image') => {
    if (data instanceof Object && Object.keys(data).includes("placename")) {
      if (mode === 'user') {
        const { placename, coords } = data;
        const { user } = this.state;
        this.setState({
          user: {
            ...user,
            location: {
              placename,
              ...coords
            }
          }
        })
      } else {
        this.setState(data);
      }
    }
  }

  selectTag = (tagRow) => {
    const { query, state } = extractFromTagsRef(this.rTags);
    const tagObj = tagRow instanceof Object ? tagRow : {};
    const { name, id } = tagObj;
    const { selectedTags } = this.state;
    let newTag = null;
    if (typeof name == "string") {
      state.query = ""
      newTag = {
        id: name.toLowerCase(),
        name: name.trim()
      };
    }
    if (newTag instanceof Object) {
      if (newTag.name.length > 2) {
        selectedTags.push(newTag);
        this.setState({ selectedTags });
      }
    }
  };

  handleDrag = (tag, currPos, newPos) => {
    let tags = this.state.selectedTags;
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);
    this.setState({ selectedTags: tags });
  };

  deleteTag = async tagIndex => {
    if (typeof tagIndex === "number" && tagIndex >= 0) {
      let selTags = this.state.selectedTags;
      if (tagIndex < selTags.length) {
        selTags.splice(tagIndex, 1);
        this.setState({ selectedTags: selTags });
      }
    }
  };

  updateValue = e => {
    if (e.target) {
      const { name, value } = e.target
      const params = {
        [name]: value
      }
      this.setState(params)
    }
  }

  updateDescription = (desc) => {
    this.setState({
      description: desc
    })
  }

  /*   updateEmbed = e => {
      if (e.target) {
        const { value } = e.target
        const { valid, type, provider } = validPlayerUrlType(value);
        const params = {
          embed: value,
          embedValid: valid,
          embedMsg: valid ? [type, provider].join(': ') : "invalid format"
        }
        this.setState(params)
      }
    }; */

  updateUserValue = async e => {
    if (e.target) {
      const { name, value } = e.target
      const params = {
        [name]: value
      }
      switch (name) {
        case 'identifier':
          if (value.length > 5 && /[^@]*\w[^@]*@\w+/.test(value)) {
            await matchUser(value).then(user => {
              if (user.identifier) {
                if (user.status > 0) {
                  this.setState({ ...params, user });
                }
              }
            })
          }
          break;
        case 'orgName':
        case 'urls':
          const { user } = this.state;
          this.setState({ ...params, user: { ...user, ...params } });
          break
      }
    }
  }

  updateLinks = async (link, mode = 'user') => {
    const { user, imageUrls } = this.state;
    const imageMode = mode === 'image';
    const refUrls = imageMode ? imageUrls : user.urls;
    const linkItems = refUrls instanceof Array ? refUrls.map(u => {
      const { type, uri } = u;
      return { type, uri }
    }) : []
    let save = false
    if (link instanceof Object) {
      const keys = Object.keys(link);
      if (keys.includes("remove")) {
        const { remove } = link
        if (remove >= 0 && remove < linkItems.length) {
          linkItems.splice(remove, 1);
          save = true;
        }
      } else if (keys.includes("uri")) {
        const { uri, type, index } = link
        if (notEmptyString(uri, 6) && notEmptyString(type, 2)) {
          const newLink = { uri, type };
          if (index >= 0 && index < refUrls.length) {
            const oldLink = linkItems[index]
            if (oldLink.uri !== newLink.uri || oldLink.type !== newLink.type) {
              linkItems[index] = newLink
              save = true
            }
          } else {
            linkItems.push(newLink)
            save = true
          }
        }
      }
      if (save) {
        if (imageMode) {
          this.setState({ imageUrls: linkItems });
        } else {
          this.setState({ user: { ...user, urls: linkItems } });
        }
      }
    }
  }

  updateImageLinks = async (link) => {
    await this.updateLinks(link, 'image');
  }

  addUrl = (mode = 'user') => {
    const { user, imageUrls } = this.state
    
    const imageMode = mode === "image";
    const refUrls = imageMode ? imageUrls : user.urls
    const linkItems = refUrls instanceof Array ? refUrls : []
    const newIndex = linkItems.length
    linkItems.push({ uri: "", type: "web", index: newIndex, key: ['url', 'web', newIndex].join('-') })
    const edited = imageMode ? { imageUrls: linkItems } : { user: { ...user, urls: linkItems } }
    this.setState(edited)
  }

  updateEmbeds = async (embed = null) => {
    const { user } = this.state;
    const { img } = this.props;
    if (embed instanceof Object && user instanceof Object) {
      const imgId = extractId(img);
      const keys = Object.keys(embed);
      const { uri, title, approved, index, remove } = embed
      let updateValid = false;
      const { embeds } = user;

      const embedItems = embeds instanceof Array ? embeds : [];
      if (keys.includes('remove') && typeof remove === 'number' && keys.length < 2) {
        if (remove >= 0 && remove < embedItems.length) {
          embedItems.splice(remove, 1);
          updateValid = true;
        }
      } else {
        const refIndex = (index => 0 && index < embeds.length) ? index : embedItems.findIndex(row => row.uri === uri);
        const updateMode = refIndex >= 0 && refIndex < embedItems.length;
        updateValid = validUrl(uri) && notEmptyString(title);
        if (updateValid) {
          const newElement = { uri, title, approved: approved !== false, mode: "image", image: imgId };
          if (updateMode) {
            embedItems[refIndex] = newElement;
          } else {
            embedItems.push(newElement);
          }
        }
      }
      if (updateValid) {
        this.setState({ user: { ...user, embeds: embedItems } });
      }
    }
  }

  updateArtistValue = (e) => {
    if (e instanceof Object && e.target) {
      const { name, value } = e.target;
      const fn = name.split("_").pop();
      const { artist } = this.state;
      artist[fn] = value;
      this.setState({artist});
    }
  }

  approveClaim = () => {
    const { artist, saving } = this.state;
    const { approved } = artist;
    artist.approved = approved !== true ? true : false; 
    this.setState({artist});
    if (!saving) {
      this.updateImageData(false);
    }
  }

  copyArtistCaption = async () => {
    const { artist } = this.state;
    if (artist instanceof Object && notEmptyString(artist.caption)) {
      if (window.isSecureContext) {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(artist.caption);
        }
      }
    }
  }

  addEmbed = () => {
    const { user } = this.state
    const { img } = this.props
    const hasUser = user instanceof Object
    const imgId = extractId(img);
    const embedItems = extractEmbedsFromUser(user, imgId);
    if (hasUser) {
      const newIndex = embedItems.length
      embedItems.push({ uri: "", title: "", type: 'image', image: imgId, index: newIndex, key: ['embed', newIndex].join('-') })
      const edited = { user: { ...user, embeds: embedItems } }
      this.setState(edited)
    }
  }

  addImageUrl = () => {
    this.addUrl('image')
  }

  render() {
    const {
      currId,
      selectedTags,
      caption,
      topic,
      description,
      imageUrls,
      placename,
      coords,
      suggestions,
      user,
      identifier,
      displayName,
      orgName,
      artist
    } = this.state;
    const { urls } = user;
    const {img } = this.props;
    const imgId = extractId(img);
    const urlItems = urls instanceof Array ? urls.filter(url => url instanceof Object).map((urlItem, ui) => {
      const key = ['url', urlItem.type, ui].join('-');
      return { ...urlItem, key, index: ui }
    }) : [];
    const embedItems = extractEmbedsFromUser(user, imgId).map((embedItem, ei) => {
      const key = ['embed', ei].join('-');
      return { ...embedItem, key, index: ei }
    });
    const userLoc = extractPlacenameAndGeo(user);
    const userPlacename = userLoc.placename;
    const userCoords = userLoc.geo;
    const imageUrlItems = imageUrls instanceof Array ? imageUrls.filter(url => url instanceof Object).map((urlItem, ui) => {
      const key = ['url', urlItem.type, ui].join('-');
      return { ...urlItem, key, index: ui }
    }) : [];
    const rTagsClassNames = { searchWrapper: "textfield textfield-long" };
    const delimiters = ['Enter', 'Tab'];
    const notHasEmbed = embedItems.length < 1;
    const hasArtist = notEmptyString(artist.name);
    const cls = artist.approved ? ["check"] : [];
    cls.push("square outline");
    const claimIconClassNames = cls.join(" ");
    return (
      <div className="edit-panel">
        <ReactTags
          ref={this.rTags}
          tags={selectedTags}
          delimiters={delimiters}
          suggestions={suggestions}
          classNames={rTagsClassNames}
          onDelete={this.deleteTag}
          onAddition={this.selectTag}
          onInput={this.selectTag}
          inline={true}
          allowNew={true}
        />
        <input
          name="caption"
          type="text"
          value={caption}
          minLength={2}
          size={64}
          placeholder="Brief caption"
          required
          className="textfield textfield-long"
          onChange={this.updateValue}
        />
        <input
          name="topic"
          type="text"
          value={topic}
          minLength={2}
          size={64}
          placeholder="Topic"
          className="textfield textfield-long"
          onChange={this.updateValue}
        />
        <ReactQuill theme="snow" value={description} onChange={this.updateDescription} />

        <Location placename={placename} coords={coords} update={this.updateLocation} mode="image" editable={true} />
        {hasArtist && <div className="artist">
          <h4>Claimed by:</h4>
          <input
            name="artist_name"
            type="text"
            value={artist.name}
            size={64}
            placeholder="Artist name"
            className="textfield textfield-long"
            onChange={this.updateArtistValue}
          />
          <input
            name="artist_email"
            type="email"
            value={artist.email}
            minLength={2}
            size={128}
            placeholder="Artist email"
            className="textfield textfield-long"
            onChange={this.updateArtistValue}
          />
          <input
            name="artist_orgName"
            type="text"
            value={artist.orgName}
            size={64}
            placeholder="Artist's organisation"
            className="textfield textfield-long"
            onChange={this.updateArtistValue}
          />
          <div className="artist-caption">
            <label>Suggested caption</label>
            <p className="caption-text">{artist.caption}</p>
            <Icon className="copy outline" onClick={() => this.copyArtistCaption()} />
          </div>
          <p className="checkbox-row status">
            <Icon
              className={claimIconClassNames}
              onClick={this.approveClaim}
            />
            <label onClick={this.approveClaim}>
              Approved
            </label>
          </p>
        </div>}
        <div className="column embed-group">
          {embedItems.map(embed => <EmbedItem {...embed} imgId={currId} update={this.updateEmbeds} />)}
          {notHasEmbed && <Button onClick={this.addEmbed}>
            <Icon className="plus" />
            <span className="text-label">Add media embed</span>
          </Button>}
        </div>
        <div className="column uri-group">
          {imageUrlItems.map(url => <LinkItem {...url} update={this.updateImageLinks} />)}
          <Button onClick={this.addImageUrl}>
            <Icon className="plus" />
            <span className="text-label">Add new web link for the image</span>
          </Button>
        </div>
        <div className="user-divider">
          <hr />
        </div>
        <input
          name="identifier"
          type="email"
          value={identifier}
          minLength={5}
          size={128}
          placeholder="Attribution"
          required
          className="textfield textfield-long"
          onChange={this.updateUserValue}
        />
        <input
          name="orgName"
          type="text"
          value={orgName}
          minLength={5}
          size={128}
          placeholder="Organisation name"
          required
          className="textfield textfield-long"
          onChange={this.updateUserValue}
        />
        <Location placename={userPlacename} coords={userCoords} update={this.updateLocation} mode="user" editable={true} />
        <div className="column uri-group">
          {urlItems.map(url => <LinkItem {...url} update={this.updateLinks} />)}
          <Button onClick={this.addUrl}>
            <Icon className="plus" />
            <span className="text-label">Add user web resource</span>
          </Button>
        </div>
        <p><span className="display-name">{user.displayName}</span> (<em className="user-role">{user.role}</em> ) </p>
        <div className="actions">
          <Icon className="save" onClick={this.updateImageData}>
            <span className="label">Save</span>
          </Icon>
        </div>
      </div>
    );
  }
}

export default ImageEdit