import React, { Component } from 'react'
import { transformCloudinary, transformLocal } from '../../modules/utils'
import config from "../../config/config";

class Picture extends Component {

  urlTransform = (img, size, density) => {
    if (density) {
      density = ' ' + density
    } else {
      density = ''
    }
    const { cloudinary } = config
    let uri = ""
    switch (img.source) {
      case 'local':
        uri = transformLocal(img, size)
        break
      default:
        size.crop = "fit"
        size.quality = cloudinary.quality
        uri = transformCloudinary(img, cloudinary, size)
        break
    }
    return `${uri}${density}`
  }

  mediaTransform = (size) => {
    let comps = [];
    if (size.min) {
      comps.push(`(min-width: ${size.min}px)`);
    }
    if (size.max) {
      comps.push(`(max-width: ${size.max}px)`);
    }
    return comps.join(' and ');
  }

  render() {
    const { sizes, img, className } = this.props;
    const defaultSize = sizes.find(size => size.default);
    let cls = ['image-set']
    if (className) {
      cls.push(className);
    }
    let wrapperClasses = cls.join(' ');
    return (
      <picture className={wrapperClasses}>
        {sizes.map((size, index) => (
          <source
            srcSet={this.urlTransform(img, size, '1x')}
            media={this.mediaTransform(size)}
            key={index} />
        ))}
        {img.hasPublicId ? (
          <img
            src={this.urlTransform(img, defaultSize)}
          />
        ) : (
          <img src={img.uri} />
        )}

      </picture>
    )
  }
}

export default Picture
